/* Hero Component
  Description: Hero Template Homepage.
*/
// Import React and Gatsby Elements
import React from 'react'

// Import Utilities and Style Elements
import { 
	Container, 
	HeroParagraph,
  HeroCopyHrefLink,
  CopyIntroInternalLink
} from '../../elements/'

const Hero = () => {
  return (
    <Container>
			<h1>Hello, I'm Martin.</h1>
      <HeroParagraph>Originally from Southern California, now living in Vancouver, BC, I'm a top 200 National Cyber League competitor, who loves testing my cyber security skills by competing in CTFs.</HeroParagraph>
      <HeroParagraph>As I prepare for my OSCP Certification, I've been keeping detailed notes on <HeroCopyHrefLink
        href="https://www.notion.so/martin-guzman/OSCP-Prep-837a4102b1864192aa2c9642edf4469f"        
        aria-label="Navigation"
        role="button"
        target="_blank">Notion</HeroCopyHrefLink> <span role="img" aria-label="Emoji Notebook with Decorative Cover">📔</span> (<HeroCopyHrefLink
        href="https://www.notion.so/martin-guzman/OSCP-Prep-837a4102b1864192aa2c9642edf4469f"        
        aria-label="Navigation"
        role="button"
        target="_blank">check them out here</HeroCopyHrefLink>) and aquired 120+ continuous days of hacking with a Top 2% Glabal Ranking of OSCP focused paths/rooms on <HeroCopyHrefLink
        href="https://tryhackme.com/p/Martin.Guzman/"        
        aria-label="Navigation"
        role="button"
        target="_blank">TryHackMe</HeroCopyHrefLink>.</HeroParagraph>
        <HeroParagraph>Follow my journey and be my study buddy <span role="img" aria-label="Emoji Happy Face">😀</span> as I <CopyIntroInternalLink to="/articles/">write</CopyIntroInternalLink> about and <HeroCopyHrefLink
        href="https://tryhackme.com/p/Martin.Guzman/"        
        aria-label="Navigation"
        role="button"
        target="_blank">train</HeroCopyHrefLink> for my OSCP Certification.</HeroParagraph>
    </Container>
  )
}
export default Hero       